import { createContext, ReactNode, useState } from 'react';

export interface FeatureFlagContextState {
  // this is the name of the flag used to control the feature that will be referenced
  vesselPictureMapEnabled: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextState>(
  {} as FeatureFlagContextState
);

interface Props {
  children: ReactNode;
}

export const FeatureFlagProvider = ({ children }: Props) => {
  const [vesselPictureMapEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_VESSEL_PICTURE_MAP_ENABLED === 'true'
  );

  return (
    <FeatureFlagContext.Provider value={{ vesselPictureMapEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
