import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { OktaProvider } from '../contexts/OktaContext';
import { Layout } from '@org-crowley/enterprise-react-component-library';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';
import { LayoutContents } from 'components/LayoutContents/LayoutContents';
import { useDataDog } from '../utils/useDataDog';

export interface NavTab {
  displayName: string;
  pathString: string;
}

function StarterApp({ Component, pageProps }: AppProps) {
  useDataDog();
  const [userName, setUserName] = useState('');
  const router = useRouter();

  return (
    <>
      <Head>
        <title>React Starter</title>
      </Head>
      <OktaProvider setUserName={setUserName} userName={userName}>
        <FeatureFlagProvider>
          <Layout
            masthead={{
              title: 'React Starter',
              onLogoClick: () => {
                router.push('/');
              },
              name: userName
            }}
          >
            <LayoutContents>
              <Component {...pageProps}></Component>
            </LayoutContents>
          </Layout>
        </FeatureFlagProvider>
      </OktaProvider>
    </>
  );
}

export default StarterApp;
