import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export const useDataDog = () => {
  useEffect(() => {
    /**
     * Hook is used before the feature flag context
     * so we must access the env vars directly.
     */
    const datadogEnabled =
      process.env.NEXT_PUBLIC_FEATURE_DATA_DOG_RUM === 'true';

    const varsPopulated =
      !!process.env.NEXT_PUBLIC_DATADOG_APP_ID &&
      !!process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
      !!process.env.NEXT_PUBLIC_DATADOG_SITE &&
      !!process.env.NEXT_PUBLIC_DATADOG_SERVICE &&
      !!process.env.NEXT_PUBLIC_DATADOG_ENV;

    if (datadogEnabled && varsPopulated) {
      console.log('Datadog enabled');
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: process.env.NEXT_PUBLIC_DATADOG_SITE,
        service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
        env: process.env.NEXT_PUBLIC_DATADOG_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogRum.startSessionReplayRecording();
    } else {
      if (varsPopulated) {
        console.log(
          'Datadog disabled: One or more environment variables are not populated'
        );
      } else {
        console.log('Datadog disabled: Feature flag is set to false');
      }
    }

    return () => {
      if (datadogEnabled) {
        datadogRum.stopSessionReplayRecording();
      }
    };
  }, []);
};
